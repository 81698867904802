<template>
    <div class="container">
        <el-row :gutter="20" v-if="loading">
            <el-col :md="12" :offset="0">
                <el-skeleton style="width: 100%" animated>
                    <template slot="template">
                        <div style="padding: 34px;">
                            <el-skeleton-item variant="img" style="width: 100%; height:400px" />
                        </div>
                        <div style="padding: 14px;"></div>
                    </template>
                </el-skeleton>
            </el-col>
            <el-col :md="12" :offset="0">
                <el-skeleton style="width: 100%; padding:30px" animated v-if="loading">
                    <template slot="template">
                        <div style="padding: 14px;">
                            <el-skeleton-item variant="h3" style="width: 50%;" />
                            <div
                                style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                            >
                                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </div>
                        <div style="padding: 14px;">
                            <el-skeleton-item variant="h3" style="width: 50%;" />
                            <div
                                style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                            >
                                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </div>
                        <div style="padding: 14px;">
                            <el-skeleton-item variant="h3" style="width: 50%;" />
                            <div
                                style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                            >
                                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </div>
                        <div style="padding: 14px;">
                            <el-skeleton-item variant="h3" style="width: 50%;" />
                            <div
                                style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                            >
                                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </el-col>
        </el-row>

        <el-row :gutter="20" v-if="!loading">
            <el-col :md="12" :xs="{span:16, offset:4}">
                <el-image :src="FILES_PATH + producto.url">
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </el-col>
            <el-col :md="12" :xs="24">
                <el-row>
                    <el-col :md="24" :sm="24" :xs="{span:20, offset:1}" :offset="0">
                        <div class="datos">
                            <strong>
                                ARTÍCULO
                                <span
                                    style="font-weight: bold;
    color: #000;
}"
                                >{{ producto.codigo }}</span>
                                <span
                                    v-if="producto.codigo_barra && producto.codigo_barra != '-'"
                                >| COD</span>
                                <span
                                    v-if="producto.codigo_barra && producto.codigo_barra != '-'"
                                    style="font-weight: bold;
    color: #000;
}"
                                >{{ producto.codigo_barra }}</span>
                            </strong>

                            <h1>{{ producto.nombre.toLowerCase() }}</h1>
                        </div>

                        <small class="marca">
                            <!-- <el-button plain size="mini" type="default" style="padding:3px; margin-left:-3px; font-size:11px"> MARCA</el-button> -->
                            <el-button
                                plain
                                size="mini"
                                type="warning"
                                style="padding: 3px; margin-left: 3px; font-size: 11px"
                            >{{ producto.marca }}</el-button>
                        </small>
                        <small class="cat" v-if="producto.categoria">
                            <!-- <el-button plain size="mini" type="default" style="padding:3px; margin-left:3px; font-size:11px"> RUBRO</el-button> -->
                            <el-button
                                plain
                                size="mini"
                                type="danger"
                                style="padding: 3px; margin-left: 3px; font-size: 11px"
                                v-for="t in producto.categoria.split('/')"
                                :key="t"
                            >{{ t }}</el-button>
                        </small>

                        <small class="bultos">
                            <el-button
                                plain
                                size="mini"
                                v-if="producto.xbulto"
                                type="default"
                                style="
                padding: 3px;
                margin-left: 3px;
                margin-top: 3px;
                font-size: 11px;
              "
                            >UNIDADES x BULTO: {{ producto.xbulto }}</el-button>
                        </small>
                        <span
                            :class="'zigzag'"
                            v-if="isOferta(producto)"
                        >{{ (100 - (producto.oferta * 100 / producto.precio)).toFixed(2).replace(/\.00$/, '') }}% descuento</span>
                        <div class="hidden-sm-and-up separador-movil"></div>
                    </el-col>
                    <el-col :md="12" v-if="!verPrecios">
                        <span class="no-login">Para ver precios o realizar un pedido.</span>

                        <el-button
                            type="primary"
                            size="small"
                            @click="$router.push({ name: 'login', query: { codigo: producto.codigo } })"
                        >Ingresar</el-button>
                        <el-button
                            type="info"
                            size="small"
                            @click="
                                $router.push({ name: 'login', query: { vista: 'registro' } })
                            "
                        >Registrarse</el-button>
                    </el-col>
                    <el-col :md="24" :xs="{span:20, offset:1}" v-if="producto.descripcion">
                        <p class="descript">{{ producto.descripcion }}</p>
                    </el-col>
                    <el-col :md="12" :xs="{span:12, offset:6}" :offset="0" v-if="verPrecios">
                        <h1 class="precioTitulo">
                            <strong v-if="!producto.iva">
                                precio
                                <span style="text-transform:lowercase">sin</span> IVA :
                            </strong>
                            <strong v-if="producto.iva">
                                precio
                                <span style="text-transform:lowercase">con</span> IVA :
                            </strong>
                            <br />
                            <span class="precio">{{ producto.precio | currency }}</span>
                            <br />
                            <strong
                                style="color: #9c27b0"
                                v-if="isOferta(producto) && !producto.iva"
                            >
                                precio oferta
                                <span style="text-transform:lowercase">sin</span> IVA:
                            </strong>
                            <strong
                                style="color: #9c27b0"
                                v-if="isOferta(producto) && producto.iva"
                            >
                                precio oferta
                                <span style="text-transform:lowercase">con</span> IVA:
                            </strong>
                            <br v-if="isOferta(producto)" />
                            <span
                                class="precio oferta"
                                v-if="isOferta(producto)"
                            >{{ producto.oferta | currency }}</span>
                        </h1>
                    </el-col>
                    <el-col :md="12" :offset="0" :xs="{span:12, offset:6}"  v-if="verPrecios">
                        <div class="cantidadTitulo" style="margin-top:20px">
                            <strong v-if="producto.precio">cantidad:</strong>
                            <br />
                            <el-input-number
                                v-model="producto.cantidad"
                                :disabled="producto.agregado3"
                                style="width: 200px"
                                size="medium"
                                :min="1"
                                v-if="producto.precio"
                            ></el-input-number>
                            <br />
                            <el-button
                                v-if="producto.precio"
                                type="success"
                                :disabled="producto.agregado3"
                                size="medium"
                                @click="agregar(producto)"
                                style="width: 200px; display:block; margin-top:10px; margin-bottom:50px"
                                :loading="cargando == producto.id"
                                class="btn-grilla"
                                :plain="producto.agregado ? false : true"
                            >
                                <span v-if="producto.agregado">AGREGADO</span>
                                <span v-else>AGREGAR</span>
                            </el-button>
                        </div>
                    </el-col>
                <br>
                <br>
                <br>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { FILES_PATH, HTTP } from "@/axios"
import { EventBus } from "@/event-bus"
import foMixins from "@/mixins/foMixins.js";
export default {
    mixins: [foMixins],
    data() {
        return {
            FILES_PATH: FILES_PATH,
            producto: {},
            relacionados: [],
            activo: true,
            loading: true,
            cargando: false,
            detalles: false,
            selectProducto: {},
            verPrecios: window.sessionStorage.getItem("cliente") ? JSON.parse(window.sessionStorage.getItem("cliente")).habilitado : false,


        }
    },
    created() {
        this.getProductoByCodigo(this.$route.params.codigo)

    },
    methods: {
        getProductoByCodigo(codigo) {
            this.loading = true
            HTTP.post('/noa/getProductoByCodigo', { codigo: codigo })
                .then(res => {
                    this.loading = false
                    this.producto = res.data.datos
                    this.producto.cantidad =1
                    this.relacionados = res.data.relacionados
                })
                .catch(() => {
                    this.activo = false
                    this.loading = false

                })
        },
        agregarProducto() {
            EventBus.$emit('agregarProducto', this.producto)
        },
        isOferta(r) {
            return r.oferta < r.precio && r.oferta && r.oferta != 1;
        },
         agregar(r) {
      console.log(r);
      if (!r.cantidad) {
        this.$alert("Por favor seleccione la cantidad de artículos", {
          type: "info",
        });
      } else {
        this.cargando = r.id;
        HTTP.post("/noa/agregarProductoPedido", {
          cliente: this.cliente,
          user: this.user,
          pedido_id: this.pedido,
          producto_id: r.id,
          cantidad: r.cantidad,
          precio: this.isOferta(r) ?  r.oferta : r.precio,
        })
          .then((res) => {
              
            if (res.data) {
           
              // if (parseInt(window.sessionStorage.getItem("pedido")) < 0) {
                window.sessionStorage.setItem("pedido", res.data.pedido_id);
                window.sessionStorage.setItem("agregados", res.data.agregados);
                this.actualizarPedido(res.data.pedido_id)
               EventBus.$emit("agregados", res.data.agregados);
              // }
           this.producto.agregado = true;
              //  }, 100);
              this.$message({
                message: r.nombre + " agregado",
                type: "success",
              });
            }
          
            setTimeout(() => {
               EventBus.$emit('actualizarCarro', true)
                 this.cargando = false;
            }, 100);
          })
          .catch((e) => {
            this.$alert(
              "No se pudo agregar el producto. Refreque la página y reintente " +
                e
            );
            this.cargando = false;
          });
      }
    },
    },
}
</script>

<style lang="scss" scoped>
.descript{
    padding: 10px;
}
.datos {
    // padding: 20px;
    margin-top: 40px;
}
.datos h1 {
    margin: 0px;
    word-wrap: break-word
}
.precioTitulo strong {
    font-size: 14px;
    width: 100px;
    text-align: center;
    margin: 0 auto;
}
.precioTitulo {
    font-size: 3em;
    margin: 0px;
    text-align: left;
    line-height: 1;
}
@media screen and (max-width:768px) {
    .datos {
    padding: 0px 10px;
    margin-top: 0px;
}
.datos h1 {
    margin: 0px;
    font-size: 28px;
}
}
</style>