<template>
  <div>
    <el-card
      style="margin-top: 20px"
      shadow="always"
      :body-style="{ padding: '10px' }"
    >
      <el-row :gutter="20">
        <el-col :md="24" :offset="0">
          <el-card shadow="never" :body-style="{ padding: '20px' }">
           <el-row :gutter="20">
             <el-col :span="6" :offset="0">
               <small for="">Buscar usuario</small>
               <el-input  v-model="buscar" placeholder="" size="small" clearable @keypress.enter.native="getUsuarios()" ></el-input>
              
               
               </el-col>
             <el-col :span="2" :offset="0">
                <el-button type="primary" size="small" style="width:100%; margin-top:19px" @click="getUsuarios()" >Buscar</el-button>
             </el-col>
              <el-col :span="3" :offset="0">
                <el-button type="info" size="small" style="margin-top:19px" @click="nuevoUsuario">Agregar nuevo usuario</el-button>
             </el-col>
           
           </el-row>
          </el-card>
          
          <table class="table table-bordered table-sm" >
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Telefono</th>
                <th>Usuario</th>
                <th>Rol</th>
                <th>Acciones</th>
              
              </tr>
            </thead>
            <tbody style="min-height:550px" v-loading="buscando">
              <tr v-for="u in usuarios" :key="u.id">
                <td>{{ u.nombre }}</td>
                <td>{{ u.correo }}</td>
                <td>{{ u.telefono }}</td>
                <td>{{ u.user }}</td>
                <td>
                  <span v-if="u.rol == 1">Cliente</span>
                  <span v-if="u.rol == 99">Admin</span>
                  <span v-if="u.rol == 33">Vendedor</span>
                </td>
                 <td>
                    <el-button type="warning" icon="el-icon-edit"   size="mini" style="padding:4px"  @click="editarUsuario(u)">
                        Editar
                    </el-button>
               
                    
               
                    <el-button type="success"   size="mini" style="padding:4px;width:90px" v-if="u.habilitado == 0" @click="habilitarUsuario(u.id,1)">
                        Habilitar
                    </el-button>
                    <el-button type="danger"   size="mini" style="padding:4px;width:90px" v-if="u.habilitado == 1" @click="habilitarUsuario(u.id,0)">
                        deshabilitar
                    </el-button>
                         <el-button type="info" icon="el-icon-delete"   size="mini" style="padding:4px"  @click="eliminarUser(u)">
                        
                    </el-button>
                </td>
              </tr>
            </tbody>
          </table>
         
        </el-col>
        <el-col :md="8" :offset="0">
          <nuevo-usuario :usuario="selectUsuario" />
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import NuevoUsuario from "@/components/admin/NuevoUsuario.vue";
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
export default {
  components: { NuevoUsuario },
  data() {
    return {
     
      usuarios: [],
      buscar: "",
      habilitando:false,
      buscando:false,
      selectUsuario:undefined
    };
  },
  created() {
    this.getUsuarios();
    EventBus.$on('actualizarUsuarios', () => {
        this.getUsuarios();
    })
  },
  methods: {
      habilitarUsuario(id,e)
      {
          let titulo = 'Habilitar Usuario'
          let msj = 'El usuario podrá realizar pedidos'
          if(!e)
          {
 titulo = 'deshabilitar Usuario'
           msj = 'El usuario ya no podrá realizar pedidos'
          }
this.$confirm(msj, titulo, {
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    type: 'info',
}).then(() => {
  this.habilitando = true
    HTTP.post('/admin/habilitarUsuario', {id:id, estado:e})
      .then(res => {
  this.habilitando = false

          let accion = res.data ? 'habilitado' : 'deshabilitado'
        //   this.$alert('Usuario ' + accion)
        this.getUsuarios();
          this.$message({
              message: 'Usuario ' + accion,
              type: 'success',
              showClose: true,
            //   duration: 3000,
          });
          
      })
      .catch(() => {
  this.habilitando = false

      })
})

      },
      eliminarUser(c) {
  this.$confirm('Eliminar usuario ' + c.user, 'Eliminar Usuario', {
    confirmButtonText: 'Eliminar',
    cancelButtonText: 'Cancelar',
    type: 'success',
  }).then(() => {
    HTTP.post('/admin/eliminarUser', {
      id: c.id
    }).then(() => {
      this.$message({
        type: 'success',
        message: 'Usuario eliminado'
      });
        this.getUsuarios();
    });
  }).catch(() => {
    
  });
  
},
      nuevoUsuario() {
        EventBus.$emit('nuevoUsuario', {})
      },
      editarUsuario(u)
      {
console.log(u);
this.selectUsuario = u
EventBus.$emit('nuevoUsuario', u)
      
      },

    getUsuarios() {
      this.buscando = true
      HTTP.get("/admin/getUsuarios?buscar=" + this.buscar)
           .then(res => {
      this.buscando = false

               console.log(res.data);
               this.usuarios = res.data.data
           })
          
    },
  },
};
</script>

<style lang="scss" scoped>
</style>