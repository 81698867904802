<template>
  <div class="container">
    <div class="line"></div>
    <el-row :gutter="20">
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
        :class="[active == 'pedido' ? 'cardActive' : '']"
          class="cardAdmin"
          shadow="always"
          :body-style="{ padding: '10px' }"
          @click.native="active = 'pedido'"
        >
          <small>PEDIDOS</small>
          <br />
          <strong> {{totales.pedido || 0}}</strong>

          
        </el-card>
      </el-col>
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
           :class="[active == 'producto' ? 'cardActive' : '']"
          shadow="always"
          :body-style="{ padding: '10px' }"
            @click.native="active = 'producto'"
        >
          <small>PRODUCTOS</small>
          <br />
        <strong> {{totales.productos || 0}}</strong>
        </el-card>
      </el-col>
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
             :class="[active == 'usuario' ? 'cardActive' : '']"
          shadow="always"
          :body-style="{ padding: '10px' }"
            @click.native="active = 'usuario'"
        >
          <small>USUARIOS</small>
          <br />
         <strong> {{totales.users || 0}}</strong>
        </el-card>
      </el-col>
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
            :class="[active == 'cliente' ? 'cardActive' : '']"
          shadow="always"
          :body-style="{ padding: '10px' }"
            @click.native="active = 'cliente'"
        >
          <small>CLIENTES</small>
          <br />
        <strong> {{totales.clientes || 0}}</strong>
        </el-card>
      </el-col>
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
           :class="[active == 'categoria' ? 'cardActive' : '']"
          shadow="always"
          :body-style="{ padding: '7.5px' }"
           @click.native="active = 'categoria'"
        >
          <small style="display:block; line-height:1.2">CATEGORÍAS Y <br> MARCAS</small>
       
          <strong> {{totales.categorias || 0}} | {{totales.marcas || 0}}</strong>
        </el-card>
      </el-col>
      <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
          shadow="always"
              :class="[active == 'config' ? 'cardActive' : '']"
              @click.native="active = 'config'"
          :body-style="{ padding: '10px' }"
        >
        <i class="el-icon-edit"></i>
        <br>
          <small>Configuraciones</small>
         
        </el-card>
      </el-col>
       <el-col :md="3" :sm="4" :xs="6" :offset="0">
        <el-card
          class="cardAdmin"
          shadow="always"
              :class="[active == 'mensajes' ? 'cardActive' : '']"
              @click.native="active = 'mensajes'"
          :body-style="{ padding: '10px' }"
        >
        <i class="el-icon-message"></i>
        <br>
          <small>Registros</small>
         
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :md="24" :offset="0">
          <admin-pedido v-if="active === 'pedido'" />
          <admin-producto v-if="active === 'producto'" />
          <admin-usuario v-if="active === 'usuario'" />
          <admin-cliente v-if="active === 'cliente'" />
          <admin-cat v-if="active === 'categoria'" />
          <admin-config v-if="active === 'config'" />
          <admin-notificaciones v-if="active === 'mensajes'" />
          
     </el-col>
    </el-row>
  </div>
</template>

<script>
import {HTTP} from "@/axios"
import { EventBus } from "@/event-bus";
import adminPedido from "@/components/admin/pedidos"
import adminProducto from "@/components/admin/productos"
import adminUsuario from "@/components/admin/usuarios"
import adminCliente from "@/components/admin/clientes"
import adminCat from "@/components/admin/categorias"
import adminConfig from "@/components/admin/adminConfig"
import adminNotificaciones from "@/components/admin/adminNotificaciones"
export default {
    components: {
        adminPedido,
        adminProducto,
        adminUsuario,
        adminCliente,
        adminCat,
        adminConfig,
        adminNotificaciones

    },
  data() {
    return {
        totales:{},
        active:'pedido',
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  created() {
    EventBus.$on('actualizarLista', (r) => {
      console.log(r);
      this.getTotales()
    })
   this.getTotales()
   this.$route.query.tabla = this.active
//    this.$router.replace({name:'admin', query: {tabla: this.active}}).catch()
  },
  methods: {
   getTotales()
   {
       HTTP.get('/admin/getTotales')
           .then(res => {
               console.log(res.data);
               this.totales = res.data
           })
   }
  },
};
</script>

<style>
.el-radio-button--mini .el-radio-button__inner {
    padding: 3px 15px;
    font-size: 12px;
    border-radius: 0;
}
</style>
<style lang="scss" scoped>

.cardAdmin {
    margin-top: 20px; text-align: center; cursor:pointer; transition:all 0.2s;
    height:64px
    // border-bottom:3px solid #e1e1e1;

}
.cardAdmin:hover {
    // border-bottom:3px solid #D32019;
    color:#fff;
    background-color:#212121;
}
.cardActive {
    // border-bottom:3px solid #D32019;
    color:#fff;
    background-color:#D32019;
}
</style>