<template>
  <div>
 
      <el-row :gutter="20">
        <el-col :md="8" :offset="0">
          <el-card
            shadow="never"
            :body-style="{ padding: '20px' }"
            style="height:550px"
          >
            <div slot="header">
              Agregar categoría o marca
            </div>
            <el-form
              :model="cat"
              ref="cat"
              size="small"
              :label-position="'top'"
            >
              <el-form-item
                label="Nombre"
                prop="nombre"
                :rules="[
                  { required: true, message: 'obligatorio', trigger: 'blur' },
                ]"
              >
                <el-input v-model="cat.nombre"></el-input>
              </el-form-item>
              <el-form-item
                label="Tipo"
                prop="tipo"
                :rules="[
                  { required: true, message: 'obligatorio', trigger: 'blur' },
                ]"
              >
                <el-select
                  v-model="cat.tipo"
                  style="width:100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="c in tipos"
                    :key="c.id"
                    :label="c.nombre"
                    :value="c.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button
                type="primary"
                @click="agregarcat('cat')"
                style="width:100%; display:block"
                :loading="agregando"
                >AGREGAR</el-button
              >
              <br />
              <el-button
                @click="modalCat = false"
                style="width:100%; display:block"
                >Cancelar</el-button
              >
            </el-form>
          </el-card>
        </el-col>
        <el-col :md="8" :offset="0">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
            <div slot="header">
              <el-input
                v-model="buscarCat"
                placeholder="buscar categoría"
                size="mini"
                style="width:60%"
                clearable
              ></el-input>
            </div>
            <el-table
              :data="
                categorias.filter(
                  (data) =>
                    !buscarCat ||
                    data.nombre.toLowerCase().includes(buscarCat.toLowerCase())
                )
              "
              height="500"
              fit
              stripe
              size="mini"
              style="width: 100%"
            >
              <el-table-column prop="nombre" label="Categoría"> 
                 <template slot-scope="scope">
                   {{scope.row.nombre}} <strong>({{scope.row.productos}})</strong>
                 </template>
              </el-table-column>
            
            
              <el-table-column  label="Visible"  width="59">
                <template slot-scope="scope">
                 <el-button
                    :type="scope.row.activo ? 'success': 'danger'"
                    size="mini"
                    
                    style="padding:4px; width:35px"
                    @click="deleteCategoria(scope.row.id, 1,scope.row.activo)"
                  >
                  <span v-if="scope.row.activo">SI</span>
                  <span v-else>NO</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>

        <el-col :md="8" :offset="0">
          <el-card shadow="never" :body-style="{ padding: '0px' }">
            <div slot="header">
              <el-input
                v-model="search"
                placeholder="buscar marca"
                size="mini"
                style="width:60%"
                clearable
              ></el-input>
            </div>
            <el-table
              :data="
                marcas.filter(
                  (data) =>
                    !search ||
                    data.nombre.toLowerCase().includes(search.toLowerCase())
                )
              "
              height="500"
              fit
              stripe
              size="mini"
              style="width: 100%"
            >
              <el-table-column prop="nombre" label="Marca"> 
                  <template slot-scope="scope">
                   {{scope.row.nombre}} <strong>({{scope.row.productos}})</strong>
                 </template>
              </el-table-column>

              <el-table-column label="visible" width="60">
                <template slot-scope="scope">
                  <el-button
                    :type="scope.row.activo ? 'success': 'danger'"
                    size="mini"
                    
                    style="padding:4px; width:35px"
                    @click="deleteCategoria(scope.row.id, 2,scope.row.activo)"
                  >
                  <span v-if="scope.row.activo">SI</span>
                  <span v-else>NO</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
  
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      tipos: [
        { id: 1, nombre: "Categoría" },
        { id: 2, nombre: "Marca" },
      ],
      agregando: false,
      modalCat: false,
      cat: {},
      categorias: [],
      marcas: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      search: "",
      buscarCat:""
    };
  },
  created() {
    EventBus.$on("modalCat", (m) => {
      this.modalCat = m;
    });

    this.getCategorias();
    this.getMarcas();
  },
  methods: {
    getCategorias() {
      HTTP.get("/admin/getCategorias").then((res) => {
        this.categorias = res.data;
      });
    },
    getMarcas() {
      HTTP.get("/admin/getMarcas").then((res) => {
        this.marcas = res.data;
      });
    },
    deleteCategoria(id, tipo,accion)
    {
      let nombre = (tipo == 1) ? 'Categoría' : 'Marca';
      let msj = accion ? ' Ocultar ' + nombre : 'Mostrar ' + nombre;
       this.$confirm(msj, 'Ocultar/Mostrar', {
    confirmButtonText: (accion ? 'Ocultar' : 'Mostrar'),
    cancelButtonText: 'Cancelar',
    type: 'success',
  }).then(() => {
    HTTP.post("/admin/deleteCategoria", {id, tipo,accion})
       .then( res => {
         
      console.log(res);   
          this.getCategorias();
              this.getMarcas();
      
    });
  }).catch(() => {
    
  });
   
    },
    agregarcat(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.agregando = true;
          HTTP.post("/admin/agregarCategoria", this.cat)
            .then((res) => {
              console.log(res.data);
              this.getCategorias();
              this.getMarcas();
              if (this.cat.tipo === 1) {
                this.$alert("Categoría agregada", { type: "success" });
                this.agregando = false;
                this.resetForm(formName);
                this.cat = {};
              }
              if (this.cat.tipo === 2) {
                this.$alert("Marca agregada", { type: "success" });
                this.agregando = false;
                this.resetForm(formName);
                this.cat = {};
              }
            })
            .catch((e) => {
              this.$alert("La categoría o marca ya fue agregada " + e, { type: "error" });
              this.agregando = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped></style>
