<template>
  <div>
   
    <el-row>
      <el-col :md="24" :offset="0">
        <div class="pedidos">
          <el-table
            :data="pedidos.data"
            :height="500"
            v-loading="pasandoPag"
            border
            stripe
            size="mini"
            fit
            v-if="!$isMobile()"
          >
            <el-table-column prop="nroPedido" label="#Pedido" width="95">
              <template slot-scope="scope">
                <a href="#" @click.prevent="openUrl(scope.row.nroPedido)">
                  {{ scope.row.nroPedido }}
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Fecha compra" width="120">
              <template slot-scope="scope">
                {{
                  $date(scope.row.fecha_finalizado).format("DD-MM-YYYY HH:mm")
                }}
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Última revisión" width="120"  >
              <template slot-scope="scope">
                <span v-if="scope.row.fecha_confirmado">
                  {{
                    $date(scope.row.fecha_confirmado).format("DD-MM-YYYY HH:mm")
                  }}
                </span>
                <span v-else> sin revisar </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="estado_nombre"
              label="Estado"
              width="95"
            ></el-table-column>
          <el-table-column prop="total" label="Total" width="95">
              <template slot-scope="scope">
                {{ scope.row.total_pedido | currency }}
              </template>
            </el-table-column>
              <el-table-column prop="total" label="Enviar a ">
              <template slot-scope="scope">
                {{ scope.row.direccion  }}
                <!-- ({{ scope.row.cuit }}) -->
              </template>
            </el-table-column> 
            <el-table-column prop="total" label="Acciones" width="120">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openUrl(scope.row.nroPedido)"
                  >detalles</el-button
                >
                <!--                   
                 <i
                    class="el-icon-plus"
                   
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                    v-if="!selectPedido"
                  ></i> -->
                <!-- <i
                    class="el-icon-minus"
                    v-if="scope.row.id == selectPedido"
                    @click="verMasAdmin(0)"
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                  ></i> -->
              </template>
            </el-table-column>
          </el-table>
  <el-table
            :data="pedidos.data"
            :height="500"
            v-loading="pasandoPag"
            border
            stripe
            size="mini"
            fit
            v-if="$isMobile()"
          >
            <el-table-column prop="nroPedido" label="#Pedido" width="90">
              <template slot-scope="scope">
                <a href="#" @click.prevent="openUrl(scope.row.nroPedido)">
                  {{ scope.row.nroPedido }}
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Fecha compra" width="120">
              <template slot-scope="scope">
                {{
                  $date(scope.row.fecha_finalizado).format("DD-MM-YYYY HH:mm")
                }}
              </template>
            </el-table-column>
          
            
            <el-table-column
              prop="estado_nombre"
              label="Estado"
             
            ></el-table-column>
         
            <el-table-column prop="total" label="Acciones" >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openUrl(scope.row.nroPedido)"
                  >detalles</el-button
                >
                <!--                   
                 <i
                    class="el-icon-plus"
                   
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                    v-if="!selectPedido"
                  ></i> -->
                <!-- <i
                    class="el-icon-minus"
                    v-if="scope.row.id == selectPedido"
                    @click="verMasAdmin(0)"
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                  ></i> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="width: 100%; padding: 15px"
            background
            @current-change="nextPage"
            :pager-count="10"
            :page-size="10"
            layout=" prev, pager, next"
            :total="pedidos.total"
          >
          </el-pagination>
          <!-- <el-card
              shadow="always"
              :body-style="{ padding: '0px' }"
            
            >
            <el-pagination
  background
  layout="prev, pager, next"
  :total="1000">
</el-pagination>
             </el-card> -->
          <detalle-pedido
            v-if="selectPedido"
            :pedido_id="selectPedido"
            :cliente_id="cliente"
            :datos="datos"
            :estado="estado"
          ></detalle-pedido>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import foMixins from "@/mixins/foMixins.js";
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
import detallePedido from "@/components/detallePedido";
export default {
  components: { detallePedido },

  mixins: [foMixins],
  data() {
    return {
      selectPedido: false,
      pasandoPag: true,
      cuenta: {},
      estado: 0,
      registrando: false,
      cargando: false,
      pedidos: [],
      page:1,
    };
  },
  created() {
    EventBus.$on("verMas", (v) => {
      this.selectPedido = v;
    });
    EventBus.$on("cancelarPedido", () => {
      this.getDatosCuenta();
      this.selectPedido = 0;
    });
    this.getDatosCuenta();
  },
  methods: {
    actualizarCliente(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registrando = true;
          HTTP.post("/noa/actualizarCliente", this.cuenta)
            .then((res) => {
              this.registrando = false;
              this.agregado = true;
              console.log(res);
              this.$alert("Datos actualizados", { type: "success" });
            })
            .catch(() => {
              this.registrando = false;
              this.$alert(
                "No se pudo actualizar el cliente, verifique los datos ingresados y reitente"
              );
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    verMas(p) {
      console.log(p);
      this.selectPedido = p.id;
      this.estado = p.estado;
    },
     nextPage(p) {
      this.selectPedido = undefined;
      this.pasandoPag= true
      this.page = p;
      setTimeout(() => {
        this.getDatosCuenta();
      }, 100);
    },
      openUrl(pedido){
      // console.log(window.location);
      // console.log(pedido);
      let url = window.location.origin+'/#/pedido/'+pedido+'?origen=micuenta'
      window.open(url, '_blank')
    },
    getDatosCuenta() {
      HTTP.post("/noa/getDatosCuenta", { user_id: this.user, page:this.page })
        .then((res) => {
          this.pasandoPag = false;
          this.cuenta = res.data;
          this.pedidos = res.data.pedidos;
        })
        .catch(() => {
          this.$alert("No se pudo recuperar la información de la cuenta");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pendiente {
  background: cornsilk;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.cancelado {
  background: #f44336;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.confirmado {
  background: #ffc107;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.preparacion {
  background: #2196f3;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.camino {
  background: #ffc107;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.entregado {
  background: #cddc39;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.pedidos {
  padding: 0px 0px;
  min-height: 500px;
}
.pedidos h3 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0;
  padding: 0px;
  border-bottom: 2px solid #e1e1e1;
  margin-bottom: 10px;
  padding-bottom: 6px;
  padding-top: 7px;
}
.pedidos small {
  color: #f44336;
  text-transform: capitalize;
}
.datos {
  background-color: #fafafa;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  border-right: 1px solid #f1f1f1;
  padding-top: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 768px) {
.pedidos {
  padding:15px;
  min-height: 500px;
  margin: 0px auto;
  display: block;
  width: 100%;
}
  
}
</style>