<template>
  <div>
    <el-card
      style="margin-top: 20px"
      shadow="always"
      :body-style="{ padding: '20px' }"
    >
      <el-row :gutter="20">

        <el-col :md="24" :offset="0">
          <nuevo-cat />
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import NuevoCat from "@/components/admin/NuevoCat.vue";
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
export default {
  components: { NuevoCat },
  data() {
    return {
      clientes: [],
    };
  },
  created() {
    this.getClientes();
    EventBus.$on('actualizarClientes', () => {
        this.getClientes();
    })
  },
  methods: {
      habilitarUsuario(id,e)
      {
          let titulo = 'Habilitar Usuario'
          let msj = 'El usuario podrá realizar pedidos'
          if(!e)
          {
 titulo = 'deshabilitar Usuario'
           msj = 'El usuario ya no podrá realizar pedidos'
          }
this.$confirm(msj, titulo, {
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    type: 'info',
}).then(() => {
    HTTP.post('/admin/habilitarUsuario', {id:id, estado:e})
      .then(res => {
          let accion = res.data ? 'habilitado' : 'deshabilitado'
        //   this.$alert('Usuario ' + accion)
        this.getClientes();
          this.$message({
              message: 'Usuario ' + accion,
              type: 'success',
              showClose: true,
            //   duration: 3000,
          });
          
      })
})

      },
    getClientes() {
      HTTP.get("/admin/getClientes")
           .then(res => {
               console.log(res.data);
               this.clientes = res.data.data
           })
          
    },
  },
};
</script>

<style lang="scss" scoped>
</style>