var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.nuevoCliente,"width":"80%","top":"1vh"},on:{"update:visible":function($event){_vm.nuevoCliente=$event}}},[_c('div',{staticStyle:{"padding":"10px"}},[(_vm.agregado)?_c('div',[_c('h1',[_vm._v("Nueva cuenta agregada")]),_c('p',[_vm._v("Su cuenta ha sido creada en forma exitosa y una vez aprobada recibirá un correo con su usuario y contraseña.")]),_c('br'),_c('strong',[_vm._v("Noa Comercial")])]):_vm._e(),(!_vm.agregado)?_c('el-form',{ref:"registro",attrs:{"model":_vm.registro,"label-position":'top',"inline":false,"size":"medium"}},[_c('el-row',{attrs:{"gutter":50}},[_c('el-col',{attrs:{"md":12,"offset":0}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Razon Social","prop":"razon_social","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"min":4},model:{value:(_vm.registro.razon_social),callback:function ($$v) {_vm.$set(_vm.registro, "razon_social", $$v)},expression:"registro.razon_social"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Nombre de fantasía","prop":"fantasia"}},[_c('el-input',{model:{value:(_vm.registro.fantasia),callback:function ($$v) {_vm.$set(_vm.registro, "fantasia", $$v)},expression:"registro.fantasia"}})],1)],1),_c('el-col',{attrs:{"md":18}},[_c('el-form-item',{attrs:{"label":"Persona de contacto","prop":"nombre","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"min":4},model:{value:(_vm.registro.nombre),callback:function ($$v) {_vm.$set(_vm.registro, "nombre", $$v)},expression:"registro.nombre"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"CUIT","prop":"cuit","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"disabled":_vm.editar,"min":8},model:{value:(_vm.registro.cuit),callback:function ($$v) {_vm.$set(_vm.registro, "cuit", $$v)},expression:"registro.cuit"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Teléfono","prop":"telefono","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{model:{value:(_vm.registro.telefono),callback:function ($$v) {_vm.$set(_vm.registro, "telefono", $$v)},expression:"registro.telefono"}})],1)],1),_c('el-col',{attrs:{"md":16}},[_c('el-form-item',{attrs:{"label":"Email","prop":"correo","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{model:{value:(_vm.registro.correo),callback:function ($$v) {_vm.$set(_vm.registro, "correo", $$v)},expression:"registro.correo"}})],1)],1)],1)],1),_c('el-col',{attrs:{"md":12,"offset":0}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"label":"Dirección","prop":"direccion","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.registro.direccion),callback:function ($$v) {_vm.$set(_vm.registro, "direccion", $$v)},expression:"registro.direccion"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Localidad","prop":"localidad","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.registro.localidad),callback:function ($$v) {_vm.$set(_vm.registro, "localidad", $$v)},expression:"registro.localidad"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Provincia","prop":"provincia","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"","clearable":"","filterable":"","auto-complete":"nope"},model:{value:(_vm.registro.provincia),callback:function ($$v) {_vm.$set(_vm.registro, "provincia", $$v)},expression:"registro.provincia"}},_vm._l((_vm.provincias),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.nombre}})}),1)],1)],1),_c('el-col',{attrs:{"md":24,"offset":0}},[(!_vm.editar)?_c('el-radio-group',{staticStyle:{"padding-bottom":"20px","margin-top":"10px"},model:{value:(_vm.registro.entrega),callback:function ($$v) {_vm.$set(_vm.registro, "entrega", $$v)},expression:"registro.entrega"}},[_c('el-radio',{attrs:{"label":"misma"}},[_vm._v("Utilizar como dirección de entrega")]),_c('el-radio',{attrs:{"label":"otra"}},[_vm._v("Agregar una nueva dirección de entrega")])],1):_vm._e()],1),(_vm.registro.entrega === 'otra')?_c('div',{staticStyle:{"border-top":"1px solid #f1f1f1","clear":"both","padding":"10px"}},[_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"label":"Dirección de Entrega","prop":"entrega_direccion","rules":[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.registro.entrega_direccion),callback:function ($$v) {_vm.$set(_vm.registro, "entrega_direccion", $$v)},expression:"registro.entrega_direccion"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Localidad de emtrega","prop":"entrega_localidad","rules":[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.registro.entrega_localidad),callback:function ($$v) {_vm.$set(_vm.registro, "entrega_localidad", $$v)},expression:"registro.entrega_localidad"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Provincia de Entrega","prop":"entrega_provincia","rules":[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"","clearable":"","filterable":"","auto-complete":"nope"},model:{value:(_vm.registro.entrega_provincia),callback:function ($$v) {_vm.$set(_vm.registro, "entrega_provincia", $$v)},expression:"registro.entrega_provincia"}},_vm._l((_vm.provincias),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.nombre}})}),1)],1)],1)],1):_vm._e(),_c('el-col',{attrs:{"md":24,"offset":0}},[_c('hr')]),_c('el-col',{attrs:{"md":12,"sm":8,"offset":0}},[_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"default"},on:{"click":_vm.regresar}},[_vm._v("REGRESAR")])],1)],1),_c('el-col',{attrs:{"md":12,"sm":8,"offset":0}},[_c('el-form-item',[(!_vm.editar)?_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary","loading":_vm.registrando},on:{"loading":_vm.cargando,"click":function($event){return _vm.registrarUsuario('registro')}}},[_vm._v("AGREGAR USUARIO")]):_vm._e(),(_vm.editar)?_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary","loading":_vm.registrando},on:{"loading":_vm.cargando,"click":function($event){return _vm.registrarUsuario('registro')}}},[_vm._v("EDITAR USUARIO")]):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }