<template>
  <div>
    <el-card
      style="margin-top: 20px"
      shadow="always"
      :body-style="{ padding: '0px' }"
      v-loading="buscando"
    >
      <el-row :gutter="20">
        <el-col :md="24" >
         <el-card shadow="never" :body-style="{ padding: '20px' }">
           <el-row :gutter="20">
             <el-col :span="6" :offset="0">
               <small for="">Buscar cliente</small>
               <el-input  v-model="buscar" placeholder="" size="small" clearable @keypress.enter.native="getClientes()" ></el-input>
              
               
               </el-col>
             <el-col :span="2" :offset="0">
                <el-button type="primary" size="small" style="width:100%; margin-top:19px" @click="getClientes()" >Buscar</el-button>
             </el-col>
             <el-col :span="3" :offset="0">
                <el-button type="info" size="small" style="margin-top:19px" @click="nuevoCliente">Agregar nuevo cliente</el-button>
             </el-col>
           </el-row>
           
             
         </el-card>
         
       
         
         
          
          </el-col>
          <el-col :md="24" :offset="0">
         <el-table
         fit
         size="mini"
         stripe
         hover
         border
    :data="clientes"
    style="width: 100%; min-height:550px">
    
    <el-table-column
      label="Razon Social"
      prop="razon_social">
    </el-table-column>
     <el-table-column
      label="Fantasía"
      prop="fantasia">
    </el-table-column>
     <el-table-column
      label="Cuit"
      prop="cuit"
      width="105">
    </el-table-column>
    <el-table-column
      label="Persona de contacto"
      prop="nombre">
    </el-table-column>
    <el-table-column
      label="Télefono"
      prop="telefono">
    </el-table-column>
     <el-table-column
      label="Correo"
      prop="correo"
      width="220">
    </el-table-column>
       <el-table-column
      label="Pedidos"
      prop="pedidos"
      width="75">
    </el-table-column>
       <el-table-column
      label=""
      prop="pedidos"
      width="95">
       <template slot-scope="scope">
        <el-button type="primary" style="padding:4px" size="mini" @click="editarCliente(scope.row)">Editar</el-button>
        <el-button type="warning"  icon="el-icon-delete" style="padding:4px" size="mini" @click="eliminarCliente(scope.row)"></el-button>
        
       </template>
    </el-table-column>
    <el-table-column type="expand">
      <template slot-scope="props">
          <el-row :gutter="20">
              <el-col :md="12" :offset="0">
                  <h5>Dirección principal</h5>
                  <ul>
                      <li>Dirección: <strong>{{ props.row.direccion }}</strong></li>
                      <li>Localidad: <strong>{{ props.row.localidad }}</strong></li>
                      <li>Provincia: <strong>{{ props.row.provincia }}</strong></li>
                  </ul>
              </el-col>
              <el-col :md="12" :offset="0" v-if="props.row.direcciones.length">
                  <h5>Dirección de Entrega</h5>
                  <ul>
                      <li>Dirección: <strong>{{ props.row.direcciones[0].direccion }}</strong></li>
                      <li>Localidad: <strong>{{ props.row.direcciones[0].localidad }}</strong></li>
                      <li>Provincia: <strong>{{ props.row.direcciones[0].provincia }}</strong></li>
                  </ul>
              </el-col>
          </el-row>
          
      
      </template>
    </el-table-column>
  </el-table>
         
        </el-col>
       
          <nuevo-cliente :cliente="selectCliente" />
          <!-- </el-card> -->
      
      </el-row>
    </el-card>
  </div>
</template>

<script>
import NuevoCliente from "@/components/admin/NuevoCliente.vue";
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
export default {
  components: { NuevoCliente },
  data() {
    return {
      clientes: [],
      buscar: "",
      selectCliente:undefined,
      buscando:false
    };
  },
  created() {
    this.getClientes();
    EventBus.$on('actualizarClientes', () => {
        this.getClientes();
    })
  },
  methods: {
    nuevoCliente(){
         EventBus.$emit('nuevoCliente', (true, false));
    },
    eliminarCliente(c) {
  this.$confirm('Eliminar cuit ' + c.cuit, 'Eliminar cliente', {
    confirmButtonText: 'Eliminar',
    cancelButtonText: 'Cancelar',
    type: 'success',
  }).then(() => {
    HTTP.post('/admin/eliminarCliente', {
      id: c.id
    }).then(() => {
      this.$message({
        type: 'success',
        message: 'Cliente eliminado'
      });
        this.getClientes();
    });
  }).catch(() => {
    
  });
  
},
      habilitarUsuario(id,e)
      {
          let titulo = 'Habilitar Usuario'
          let msj = 'El usuario podrá realizar pedidos'
          if(!e)
          {
 titulo = 'deshabilitar Usuario'
           msj = 'El usuario ya no podrá realizar pedidos'
          }
this.$confirm(msj, titulo, {
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    type: 'info',
}).then(() => {
    HTTP.post('/admin/habilitarUsuario', {id:id, estado:e})
      .then(res => {
          let accion = res.data ? 'habilitado' : 'deshabilitado'
        //   this.$alert('Usuario ' + accion)
        this.getClientes();
          this.$message({
              message: 'Usuario ' + accion,
              type: 'success',
              showClose: true,
            //   duration: 3000,
          });
          
      })
})

      },
      editarCliente(cliente)
      {
        console.log(cliente);
          this.selectCliente = cliente
          EventBus.$emit('nuevoCliente', (true,cliente));
      },
    getClientes() {
      this.buscando = true;
      HTTP.get("/admin/getClientes?buscar=" + this.buscar)
           .then(res => {
               console.log(res.data);
               this.buscando = false;
               this.clientes = res.data.data
           })
           .catch(err => {
               this.buscando = false;
               console.log(err);
           });
          
    },
  },
};
</script>

<style lang="scss" scoped>
</style>