<template>
  <div>
        <div
        v-if="recuperando"
        v-loading="recuperando"
        element-loading-text="Recuperando información del pedido..."
        element-loading-spinner="el-icon-loading"
        style="height: 450px"
        ></div>

      <div class="pedido" v-if="detalle.length && !recuperando">
        <el-table :data="detalle" border stripe fit height="550" size="mini">
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="nombre" label="Producto">
            <template slot-scope="scope">
              {{ scope.row.nombre }} <strong> {{ scope.row.codigo }}</strong>
              <br />
              <small style="color: #e91e63" v-if="scope.row.bultos">
                bultos: {{ scope.row.bultos }} - unidades x bultos:
                {{ scope.row.xbulto }} -
              </small>
              <!-- <small style="color: #2196f3" v-if="scope.row.oferta">
                Oferta: descuento {{ scope.row.oferta * 100 }}%
              </small> -->
            </template>
          </el-table-column>
          <el-table-column prop="cantidad" label="Cantidad" width="120">
            <template slot-scope="scope">
                {{scope.row.cantidad}}
                <!-- <el-input-number
                    v-model="scope.row.cantidad"
                    size="mini"
                    style="width: 100%"
                    :step="1"
                    :min="1"
                    :controls="true"
                    controls-position="right"
                    @change="updateCantidadProducto(scope.row)"
                > 
              </el-input-number>-->
            </template>
          </el-table-column>
          <el-table-column prop="precio_compra"  :label="detalle[0].iva ? 'Precio con iva' : 'Precio sin IVA' " width="120">>
            <template slot-scope="scope">
          
                {{ scope.row.precio_compra | currency }}
             
            </template>
          </el-table-column>
          <el-table-column prop="precio_compra"  :label="detalle[0].iva ? 'Subtotal con iva' : 'Subtotal sin IVA' " width="120"> width="100">
            <template slot-scope="scope">
          
                {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}
              
            </template>
          </el-table-column>
        </el-table>

        <!--             
                        <td>{{totales().cantidad | number}}</td>
                        <td>{{totales().unitarios | currency}}</td>
                        <td colspan="2">{{totales().total | currency}}</td>
                    </tr>
                </tbody>
            </table> -->

        <div style="background: #f1f1f1; padding: 10px">
          <el-row :gutter="20">
            <el-col :md="8" :offset="0">
              <div class="resumen">
                <ul>
                  <li>Items: {{ detalle.length }}</li>
                  <li>Cantidad: {{ totales().cantidad }}</li>
                  <li>Items con ofertas: {{ totales().ofertas }}</li>
                </ul>
              </div>
            </el-col>
            <el-col :md="16" :offset="0">
              <div style="text-align: right">
                <h3>Total Pedido <span v-if="detalle[0].iva">con iva</span><span v-if="!detalle[0].iva">sin iva</span>: {{ totales().total | currency }}</h3>
               <el-button
                  type="primary"
                  size="mini"
                  style=""
                  @click="regresar"
                  >Regresar</el-button
                >
                  
                <el-button
                  type="warning"
                  size="mini"
                  style=""
                  v-if="estado !== 99"
                  @click="cancelarPedido"
                  >Cancelar Pedido</el-button
                > 
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

</template>

<script>
import { HTTP } from "@/axios";
import {EventBus} from "@/event-bus"
import foMixins from "@/mixins/foMixins.js";
export default {
  mixins: [foMixins],
  props:['pedido_id', 'cliente_id', 'estado'],
  data() {
    return {
      detalle: [],
      recuperando: true,
      finalizado: false,
      finalizando: false,
      total_pedido:0,
      total_productos:0,
      total_cantidad:0
    };
  },
  created() {
 
    this.getPedido();
   
  },
  methods: {
                cancelarPedido() {
      this.$confirm("Al cancelar el pedido, este no será procesado ", "Cancelar Pedido #"+ this.pedido_id, {
        confirmButtonText: "Cancelar Pedido",
        cancelButtonText: "Regresar",
        type: "danger",
      }).then(() => {
          this.recuperando = true
        HTTP.post("/noa/cancelarPedido", { id: this.pedido_id , correo: this.datos_cliente.correo})
        .then(() => {
              this.recuperando = false
              EventBus.$emit("cancelarPedido", 1)
        })
        .catch(() => {
              this.recuperando = false
           this.$alert('No se pudo cancelar el pedido, refresque la página y reintente', {type:'error'})
        });
      });

    },
    regresar()
    {
      EventBus.$emit('verMas', 0)
    },
    totales() {
      let totales = {};

      this.total_cantidad = totales.cantidad = this.detalle.reduce((a, b) => {
        return a + b.cantidad;
      }, 0);
      totales.unitarios = this.detalle.reduce((u, uw) => {
        return parseFloat(u) + parseFloat(uw.precio_compra);
      }, 0);
    this.total_pedido =  totales.total = this.detalle.reduce((t, tu) => {
        return parseFloat(t) + parseFloat(tu.precio_compra) * tu.cantidad;
      }, 0);

      totales.ofertas = this.detalle.filter((o) => {
        return o.oferta > 0;
      }).length;
      // console.log(totales);
      return totales;
    },

    getPedido() {

      setTimeout(() => {
         HTTP.post("/noa/getPedido", {
        cliente_id: this.cliente_id,
        pedido_id: this.pedido_id,
      }).then((res) => {

         this.recuperando = false;
        this.detalle = res.data;
      });
      }, 100);
     
    },
  },
};
</script>
<style>
.set-altura {
  min-height: 100px;
  max-height: 600px;
}
</style>
<style lang="scss" scoped>
tr.table-footer td {
  padding: 10px 4px !important;
  font-weight: bold;
  font-size: 16px !important;
  background: #cddc39 !important;
}
.table-striped-noa > tbody > tr:nth-child(odd) > td,
.table-striped-noa > tbody > tr:nth-child(odd) > th {
  background-color: #fce4ec5e;
}
.table-hover-noa {
  margin-bottom: 0 !important;
}
.tableFixHead {
  overflow: auto;
  height: 600px;
}
.tableFixHead thead th {
  position: sticky;
  top: -10px;
  z-index: 1;
  padding: 20px;
}

.table-hover-noa > tbody > tr:hover {
  background-color: #ffeb3b;
  cursor: pointer;
}
.vacio {
  padding: 20px;
}
.vacio img {
  display: block;
  margin: 0 auto;
  max-width: 220px;
}
.vacio h1 {
  text-align: center;
}
.vacio p {
  text-align: center;
  margin: 0 auto;
  // width: 320px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.vacio ul {
  width: 320px;
  text-align: left;
  margin: 0 auto;
}
</style>