<template>
  <div>


    <el-card style="margin-top: 10px;" shadow="always" :body-style="{ padding: '10px' }">
      <el-row :gutter="20">
         <el-col :md="4" :xs="12" :sm="12" :offset="0">
            <strong># pedido</strong>
            <el-input v-model="filtros.pedido" :disabled="filtros.cuit ? true : false" @clear="getPedidos" placeholder="Buscar por pedido" size="small" @keypress.enter.native="getPedidos" clearable></el-input>

          </el-col>
        <el-col :md="4" :xs="12" :sm="12" :offset="0">
          <strong>Cuit</strong>
          <el-input v-model="filtros.cuit" :disabled="filtros.pedido ? true : false" @clear="getPedidos" placeholder="Buscar por cuit" size="small" @keypress.enter.native="getPedidos" clearable></el-input>

        </el-col>
        <el-col :md="6" :xs="12" :sm="12" :offset="0">
          <strong>Estado</strong> <br>
          <el-select v-model="filtros.estado" value-key="id" placeholder="" size="small" style="width:100%" clearable
            filterable>
            <el-option v-for="item in estados" :key="item.id" :label="item.estado" :value="item.id">
            </el-option>
          </el-select>



        </el-col>
        <el-col :md="8">
          <el-button type="primary" size="small" style="margin-top: 18px;" @keypress.enter.native="getPedidos"
            @click="getPedidos">Filtrar</el-button>

          <el-button type="success" size="small" style="margin-top: 18px;" @click="exportPedidos" :loading="exportando">
            Filtrar y exportar</el-button>

             <el-button type="danger" style="margin-top: 18px;"  size="small" @click="ActualizarPrecios" icon="el-icon-magic-stick">
                      Fix Precios </el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-row>


      <el-col :md="24" :offset="0">
        <div class="pedidos">
          <el-table :data="pedidos.data" :height="500" v-loading="!pedidos.data || pasandoPag" border stripe size="mini"
            fit>
            <el-table-column prop="nroPedido" label="#Pedido" width="95">
              <template slot-scope="scope">
                <a href="#" @click.prevent="openUrl(scope.row.nroPedido)">
                  {{scope.row.nroPedido}}
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Fecha compra" width="120">
              <template slot-scope="scope">
                {{ $date(scope.row.fecha_finalizado).format("DD-MM-YYYY HH:mm") }}
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Última revisión" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.fecha_confirmado">
                  {{ $date(scope.row.fecha_confirmado).format("DD-MM-YYYY HH:mm") }}
                </span>
                <span v-else>
                  sin revisar
                </span>

              </template>
            </el-table-column>
            <el-table-column prop="estado_nombre" label="Estado" width="165">
              <template slot-scope="scope">
                <el-select v-model="scope.row.estado" :disabled="noCambiar(scope.row.estado)" value-key="id"
                  placeholder="" size="mini" style="width:100%" @change="CambiarEstado(scope.row)" filterable>
                  <el-option v-for="item in estados" :key="item.id" :label="item.estado" :value="item.id"
                    v-if="(item.id >= scope.row.estado)">
                  </el-option>
                </el-select>

              </template>
            </el-table-column>
            <el-table-column prop="total" label="Total" width="120">
              <template slot-scope="scope">
                {{scope.row.total_pedido | currency }}
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Cliente">
              <template slot-scope="scope">
                {{scope.row.fantasia || scope.row.nombre}}
                ({{scope.row.cuit}})
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Acciones" width="200">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="openUrl(scope.row.nroPedido)">revisar</el-button>
                <el-button type="warning" size="mini" @click="exportPdf(scope.row.nroPedido, scope.row.cliente_id)">
                  PDF
                </el-button>
               
                <!--                   
                 <i
                    class="el-icon-plus"
                   
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                    v-if="!selectPedido"
                  ></i> -->
                <!-- <i
                    class="el-icon-minus"
                    v-if="scope.row.id == selectPedido"
                    @click="verMasAdmin(0)"
                    style="
                      display: block;
                      margin-top: 20px;
                      cursor: pointer;
                      color: #e91e63;
                      font-weight: bold;
                    "
                  ></i> -->
              </template>
            </el-table-column>

          </el-table>





          <el-pagination style="width: 100%; padding:15px; float:left " background @current-change="nextPage" :page-count="10"
            layout=" prev, pager, next" :total="pedidos.total">
          </el-pagination>

          
          <!-- <el-card
              shadow="always"
              :body-style="{ padding: '0px' }"
            
            >
            <el-pagination
  background
  layout="prev, pager, next"
  :total="1000">
</el-pagination>
             </el-card> -->
          <detalle-pedido v-if="selectPedido" :pedido_id="selectPedido" :cliente_id="cliente" :datos="datos"
            :estado="estado"></detalle-pedido>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import { baseUrl,HTTP } from "@/axios";
import detallePedido from "@/components/admin/detallePedido";
import foMixins from "@/mixins/foMixins.js";
import { EventBus } from "@/event-bus";

export default {
  components: { detallePedido },
  mixins: [foMixins],
  data() {
    return {
      pasandoPag:false,
      baseUrl:baseUrl,
      exportando:false,
      estados:[],
      selectPedido: false,
      datos: {},
      pedidos: [],
      cargando: true,
      filtros: {
        page: 1,
        estado: 0,
      },
    };
  },
  created() {
    EventBus.$on("verMasAdmin", (v) => {
      this.selectPedido = v;
    });
    this.getPedidos();
    this.getEstados();
  },
  methods: {
    exportPdf(pedido_id, cliente_id) {

      let url = this.baseUrl + '/admin/exportPdf2?pedido_id=' + pedido_id + '&cliente_id=' + cliente_id
      window.open(url, '_blank')


    },
    ActualizarPrecios()
    {
           HTTP.get('/noa/corregirProductos')
               .then(res => {
                this.$alert("Se encontraro y corrigieron " + res.data)
               })
               .catch(() => {
                  this.$alert("Error al buscar productos con error")
               })
    },
    noCambiar(e)
    {
        let nocambia = [99,7,98]

        return nocambia.includes(e)
    },
    CambiarEstado(p)
    {
      let mail = [7,5,4,99]
  HTTP.post("/admin/CambiarEstado", p)
        .then((res) => {
          if(mail.includes(res.data.estado))
          {
             this.$confirm('El pedido fue actualizao. Desea enviarle un mail al cliente?', 'Cambio de estado', {
          confirmButtonText: 'Enviar aviso',
          cancelButtonText: 'Regresar',
          type: 'success'
        }).then(() => {
        HTTP.get("/admin/EnviarMail",{pedido_id:p.id})
        })
          }
          else{
  this.$message({
           message: 'Pedido actualizado',
           type: 'success',
           showClose: true,
           duration: 500,
         });
          }
       
         
        })
        .catch((e) => {
          this.$alert("Error de conexión " + e, { type: "error" });
        });
    },
    nextPage(p) {
      this.selectPedido = undefined;
      this.pasandoPag= true
      this.filtros.page = p;
      setTimeout(() => {
        this.getPedidos();
      }, 100);
    },
    verMasAdmin(p) {
      console.log(p);
      this.selectPedido = p.id;
      this.estado = p.estado;
      this.datos = p;
    },
    exportPedidos()
    {
      let url = this.baseUrl + '/admin/exportPedidos?estado='+this.filtros.estado
       window.open(url, '_blank')
    },
    getPedidos() {
      this.pasandoPag= true
      this.filtros.page = (this.filtros.estado > 0 || this.filtros.pedido) ? 1 : this.filtros.page;
      HTTP.post("/admin/getPedidos", this.filtros)
        .then((res) => {
          this.pedidos = res.data;
          this.pasandoPag= false

          
        })
        .catch((e) => {
          this.$alert("Error de conexión " + e, { type: "error" });
        });
    },
    getEstados() {
      this.pasandoPag= true
      HTTP.get("/admin/getEstados")
        .then((res) => {
          this.estados = res.data;
             
        })
        .catch((e) => {
          this.$alert("Error de conexión " + e, { type: "error" });
        });
    },
    openUrl(pedido){
      // console.log(window.location);
      // console.log(pedido);
      let url = window.location.origin+'/#/pedido/'+pedido+'?origen=admin'
      window.open(url, '_blank')
    }
  },
};
</script>

<style>
.estado-1 .el-input__inner {
  background: cornsilk;
  color: #111;
  
}
.estado-99 .el-input__inner {
  background: #f44336;
  color: #fff;
  
}
.estado-2 .el-input__inner {
  background: #ffc107;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.estado-3 .el-input__inner {
  background: #2196f3;
  color: #111;
  
}
.estado-4 .el-input__inner {
  background: #ffc107;
  color: #111;
 
}
.estado-5 .el-input__inner {
  background: #cddc39;
  color: #111;
 
}
</style>
<style lang="scss" scoped>
.filtros-admin strong {
  border-bottom: 1px solid #000;
  display: block;
  margin-bottom: 20px;
}
.filtros-admin {
  background: #e1e1e1;
  padding: 10px;
  min-height: calc(100vh - 210px);
}
.radio-admin {
  display: inline-block;
  padding-bottom: 5px;
  color: #000;
}
</style>

<style lang="scss" scoped>

.pendiente {
  background: cornsilk;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.cancelado {
  background: #f44336;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.confirmado {
  background: #ffc107;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.preparacion {
  background: #2196f3;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.camino {
  background: #ffc107;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.entregado {
  background: #cddc39;
  color: #111;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.pedidos {
  padding: 0px 0px;
  font-size: 14px;
}
.pedidos h3 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0;
  padding: 0px;
  border-bottom: 2px solid #e1e1e1;
  margin-bottom: 10px;
  padding-bottom: 6px;
  padding-top: 7px;
}
.pedidos small {
  color: #f44336;
  text-transform: capitalize;
}
.datos {
  background-color: #fafafa;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  border-right: 1px solid #f1f1f1;
  padding-top: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>