var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":8,"offset":0}},[_c('el-card',{staticStyle:{"height":"550px"},attrs:{"shadow":"never","body-style":{ padding: '20px' }}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Agregar categoría o marca ")]),_c('el-form',{ref:"cat",attrs:{"model":_vm.cat,"size":"small","label-position":'top'}},[_c('el-form-item',{attrs:{"label":"Nombre","prop":"nombre","rules":[
                { required: true, message: 'obligatorio', trigger: 'blur' },
              ]}},[_c('el-input',{model:{value:(_vm.cat.nombre),callback:function ($$v) {_vm.$set(_vm.cat, "nombre", $$v)},expression:"cat.nombre"}})],1),_c('el-form-item',{attrs:{"label":"Tipo","prop":"tipo","rules":[
                { required: true, message: 'obligatorio', trigger: 'blur' },
              ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","filterable":""},model:{value:(_vm.cat.tipo),callback:function ($$v) {_vm.$set(_vm.cat, "tipo", $$v)},expression:"cat.tipo"}},_vm._l((_vm.tipos),function(c){return _c('el-option',{key:c.id,attrs:{"label":c.nombre,"value":c.id}})}),1)],1),_c('el-button',{staticStyle:{"width":"100%","display":"block"},attrs:{"type":"primary","loading":_vm.agregando},on:{"click":function($event){return _vm.agregarcat('cat')}}},[_vm._v("AGREGAR")]),_c('br'),_c('el-button',{staticStyle:{"width":"100%","display":"block"},on:{"click":function($event){_vm.modalCat = false}}},[_vm._v("Cancelar")])],1)],1)],1),_c('el-col',{attrs:{"md":8,"offset":0}},[_c('el-card',{attrs:{"shadow":"never","body-style":{ padding: '0px' }}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('el-input',{staticStyle:{"width":"60%"},attrs:{"placeholder":"buscar categoría","size":"mini","clearable":""},model:{value:(_vm.buscarCat),callback:function ($$v) {_vm.buscarCat=$$v},expression:"buscarCat"}})],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.categorias.filter(
                (data) =>
                  !_vm.buscarCat ||
                  data.nombre.toLowerCase().includes(_vm.buscarCat.toLowerCase())
              ),"height":"500","fit":"","stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"nombre","label":"Categoría"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.nombre)+" "),_c('strong',[_vm._v("("+_vm._s(scope.row.productos)+")")])]}}])}),_c('el-table-column',{attrs:{"label":"Visible","width":"59"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"padding":"4px","width":"35px"},attrs:{"type":scope.row.activo ? 'success': 'danger',"size":"mini"},on:{"click":function($event){return _vm.deleteCategoria(scope.row.id, 1,scope.row.activo)}}},[(scope.row.activo)?_c('span',[_vm._v("SI")]):_c('span',[_vm._v("NO")])])]}}])})],1)],1)],1),_c('el-col',{attrs:{"md":8,"offset":0}},[_c('el-card',{attrs:{"shadow":"never","body-style":{ padding: '0px' }}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('el-input',{staticStyle:{"width":"60%"},attrs:{"placeholder":"buscar marca","size":"mini","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.marcas.filter(
                (data) =>
                  !_vm.search ||
                  data.nombre.toLowerCase().includes(_vm.search.toLowerCase())
              ),"height":"500","fit":"","stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"nombre","label":"Marca"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.nombre)+" "),_c('strong',[_vm._v("("+_vm._s(scope.row.productos)+")")])]}}])}),_c('el-table-column',{attrs:{"label":"visible","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"padding":"4px","width":"35px"},attrs:{"type":scope.row.activo ? 'success': 'danger',"size":"mini"},on:{"click":function($event){return _vm.deleteCategoria(scope.row.id, 2,scope.row.activo)}}},[(scope.row.activo)?_c('span',[_vm._v("SI")]):_c('span',[_vm._v("NO")])])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }