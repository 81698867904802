<template>
  <el-dialog :visible.sync="nuevoCliente" width="80%" top="1vh">
    <div style="padding:10px">
      <div v-if="agregado" >
        <h1>Nueva cuenta agregada</h1>
        <p>Su cuenta ha sido creada en forma exitosa y una vez aprobada recibirá un correo con su usuario y contraseña.</p>
        <br />
        <strong>Noa Comercial</strong>
      </div>
      <el-form
        :model="registro"
        ref="registro"
        :label-position="'top'"
        :inline="false"
        size="medium"
        v-if="!agregado"
      >
      <el-row :gutter="50">
        <el-col :md="12" :offset="0">
            <el-row :gutter="20">
          <el-col :md="12">
            <el-form-item
              label="Razon Social"
              prop="razon_social"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input :min="4" v-model="registro.razon_social"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Nombre de fantasía" prop="fantasia">
              <el-input v-model="registro.fantasia"></el-input>
            </el-form-item>
          </el-col>
            <el-col :md="18">
            <el-form-item
              label="Persona de contacto"
              prop="nombre"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input :min="4" v-model="registro.nombre"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
            
              label="CUIT"
              prop="cuit"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input :disabled="editar" v-model="registro.cuit" :min="8"></el-input>
            </el-form-item>
          </el-col>
          
          <el-col :md="12">
            <el-form-item
              label="Teléfono"
              prop="telefono"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.telefono"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <el-form-item
              label="Email"
              prop="correo"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.correo"></el-input>
            </el-form-item>
          </el-col>
        
         
         
        </el-row>
        </el-col>
        <el-col :md="12" :offset="0">
          <el-row :gutter="20">
             <el-col :md="24">
            <el-form-item
              label="Dirección"
              prop="direccion"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.direccion" :min="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Localidad"
              prop="localidad"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.localidad" :min="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Provincia"
              prop="provincia"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-select
                v-model="registro.provincia"
                style="width:100%"
                placeholder
                clearable
                filterable
                auto-complete="nope"
              >
                <el-option
                  v-for="item in provincias"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.nombre"
                ></el-option>
              </el-select>

              <!-- <el-input v-model="registro.pass" :min="8" ></el-input> -->
            </el-form-item>
          </el-col>
            <el-col :md="24" :offset="0">
            <el-radio-group v-if="!editar" v-model="registro.entrega" style="padding-bottom:20px; margin-top:10px">
              <el-radio label="misma">Utilizar como dirección de entrega</el-radio>
              <el-radio label="otra">Agregar una nueva dirección de entrega</el-radio>
            </el-radio-group>
          </el-col>
          <div
            style="border-top:1px solid #f1f1f1; clear:both; padding:10px"
            v-if="registro.entrega === 'otra'"
          >
            <el-col :md="24">
              <el-form-item
                label="Dirección de Entrega"
                prop="entrega_direccion"
                :rules="[
                  {
                    required: true,
                    message: 'campo obligatorio',
                    trigger: 'blur',
                  },
                
                ]"
              >
                <el-input v-model="registro.entrega_direccion" :min="8"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Localidad de emtrega"
                prop="entrega_localidad"
                :rules="[
                  {
                    required: true,
                    message: 'campo obligatorio',
                    trigger: 'blur',
                  },
                
                ]"
              >
                <el-input v-model="registro.entrega_localidad" :min="8"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Provincia de Entrega"
                prop="entrega_provincia"
                :rules="[
                  {
                    required: true,
                    message: 'campo obligatorio',
                    trigger: 'blur',
                  },
                
                ]"
              >
                <el-select
                  v-model="registro.entrega_provincia"
                  style="width:100%"
                  placeholder
                  clearable
                  filterable
                  auto-complete="nope"
                >
                  <el-option
                    v-for="item in provincias"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.nombre"
                  ></el-option>
                </el-select>

                <!-- <el-input v-model="registro.pass" :min="8" ></el-input> -->
              </el-form-item>
            </el-col>
          </div>
          <el-col :md="24" :offset="0">
            <hr />
          </el-col>
   <el-col :md="12" :sm="8" :offset="0">
            <el-form-item>
              <el-button
              
                type="default"
                class="btn-block"
             
                @click="regresar"
              >REGRESAR</el-button>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="8" :offset="0">
            <el-form-item>
              <el-button
              v-if="!editar"
                @loading="cargando"
                type="primary"
                class="btn-block"
                :loading="registrando"
                @click="registrarUsuario('registro')"
              >AGREGAR USUARIO</el-button>
                  <el-button
              v-if="editar"
                @loading="cargando"
                type="primary"
                class="btn-block"
                :loading="registrando"
                @click="registrarUsuario('registro')"
              >EDITAR USUARIO</el-button>
            </el-form-item>
          </el-col>
          </el-row>
          
        </el-col>
      </el-row>
      
      
      </el-form>
    </div>
   
  </el-dialog>
</template>

<script>
import { HTTP } from "@/axios"
import { EventBus } from "@/event-bus";
import provincias from "@/assets/provincias.json"
export default {
  props: ["cliente"],
  data() {
    return {
      nuevoCliente: false,
      editar: false,
      registrando: false,
      cargando: false,
      agregado: false,
      vista: 'registro',
      provincias: provincias.provincias,
      login: {},
      recuperar: {},
      registro: {
        entrega: 'misma'
      }
    };
  },
  computed: {
   updateCliente() {
     
    return this.cliente;
  },
  },
  created() {
    EventBus.$on('nuevoCliente', (c) => {
      console.log('nuevoCliente', c);
      this.nuevoCliente = true;
        if(c)
        {
          this.registro = c;
          this.registro.editar = c.id
          this.editar = true;
        }

    })
    //  this.registro = this.cliente;
      
    console.log('cliente', this.cliente);
    if (this.$route.query.vista) {
      this.vista = this.$route.query.vista
    }
  },
  methods: {
regresar()
{
this.registro= {
        entrega: 'misma'
      }
      this.nuevoCliente = false
      this.editar = false;
},


    registrarUsuario(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registrando = true
          HTTP.post('/noa/register', this.registro)
            .then(res => {
              this.registrando = false
              this.registro = {}
              //  this.agregado = true
              console.log(res)
              this.$alert('Nuevo cliente agregado/actualizado correctamente', { type: 'success' })
     this.nuevoCliente = false
          EventBus.$emit('actualizarClientes', false)
            
            })
            .catch(e => {
              this.registrando = false
              this.$alert('No se pudo crear el cliente, verifique los datos ingresados y reitente')

              if (e.response) {
                if (e.response.status === 422) {
                  this.$alert('El CUIT: ' + this.registro.cuit + ' ya posee un registro. Si no recuerda sus datos de accesos presione el boton Regresar y seleccione la opción OLVIDE MIS DATOS ', e)
                }
              }

            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    recuperarPass() {
      if (this.registro.user || this.registro.correo || this.registro.telefono) {
        console.log('recupera');
      }
      else {

        this.$alert('Debe ingresar al menos un dato', {
          type: 'error'
        })
      }
    }
  },
};
</script>

<style lang="scss">
.login > .el-card__header {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  background: #d32019;
  color: white;
  line-height: 1;
  font-size: 18px;
}
.logo {
  max-width: 260px;
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: center;
}
</style>
