<template>
    <div>
<div class="detallePedido">
      <el-row :gutter="20">
        <el-col
          :md="{ span: 16, offset: 0 }"
          :sm="{ span: 16, offset: 0 }"
          :xs="9"
          @click.native="goToHome"
        >
        <h1>PEDIDO 
           <strong>#{{$route.params.pedido}}</strong> 
           
            </h1>
         
        </el-col>
         <el-col
          :md="{ span: 4, offset: 0 }"
          :sm="{ span: 4, offset: 0 }"
          :xs="15"
          @click.native="goToHome"
        >
          <img src="@/assets/logo.png" class="pedido-logo" alt=""  />
        </el-col>
      </el-row>
      <origenPedido :data="pedido"/>
      <datos-pedido :data="pedido"/>
      <!--  -->
      
      
</div>
    </div>
</template>

<script>
// import { EventBus } from "@/event-bus";
import { HTTP } from "@/axios";
// import DatosPedido from '../components/datosPedido.vue';
    export default {
        components: {
            origenPedido: () => import("@/components/origenPedido.vue"),
            datosPedido: () => import("@/components/datosPedido.vue"),
            // 
             
        },
        watch: {
          $route (to, from){
                if(to.params.pedido !== from.params.pedido){
                    this.getPedido()
                }
    }
        },
        data() {
            return {
                pedido: undefined
            }
        },
        created() {
            this.getPedido();
        },
        methods: {
            getPedido() {
                HTTP.post('noa/getDetallePedido', {
                    id: this.$route.params.pedido
                }).then(response => {
                    this.pedido = response.data;
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        }
    
</script>

<style lang="scss" scoped>
.detallePedido h1{
    font-size: 22px;
    margin-top: 30px;
    font-weight: normal;
}
.detallePedido{
    width: 800px;
    margin: 0 auto;
}
.pedido-logo {
 height: 50px;
 padding: 20px 0px;
}
@media screen and (max-width:768px){
    .detallePedido{
    width: 93%;
    margin: 0 auto;
    display: block;
    padding: 20px;
}
.pedido-logo {
 height: auto;
 width: 100%;
 padding: 10px;
//  margin-top: -15px;
}
   .detallePedido h1{
    font-size: 18px;
    margin-top: 0px;
    font-weight: normal;
    
}
}
    

</style>