var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.nuevoUsuario,"width":"50%"},on:{"update:visible":function($event){_vm.nuevoUsuario=$event},"close":function($event){_vm.editar = false}}},[_c('div',{staticClass:"container"},[(_vm.agregado)?_c('div',{staticStyle:{"padding":"30px"}},[_c('h1',[_vm._v("Nueva cuenta agregada")]),_c('p',[_vm._v("Su cuenta ha sido creada en forma exitosa y una vez aprobada recibirá un correo con su usuario y contraseña.")]),_c('br'),_c('strong',[_vm._v("Noa Comercial")])]):_vm._e(),(!_vm.agregado)?_c('el-form',{ref:"registro",attrs:{"model":_vm.registro,"label-position":'top',"inline":false,"size":"medium"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":24}},[_c('el-form-item',{attrs:{"label":"Nombre ","prop":"nombre","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"min":4},model:{value:(_vm.registro.nombre),callback:function ($$v) {_vm.$set(_vm.registro, "nombre", $$v)},expression:"registro.nombre"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Usuario","prop":"user","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{attrs:{"disabled":_vm.editar,"min":8},model:{value:(_vm.registro.user),callback:function ($$v) {_vm.$set(_vm.registro, "user", $$v)},expression:"registro.user"}})],1)],1),_c('el-col',{attrs:{"md":12}},[(!_vm.editar)?_c('el-form-item',{attrs:{"label":"Contraseña","prop":"pass","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{model:{value:(_vm.registro.pass),callback:function ($$v) {_vm.$set(_vm.registro, "pass", $$v)},expression:"registro.pass"}})],1):_vm._e(),(_vm.editar)?_c('el-form-item',{attrs:{"label":"cambiar contraseña","prop":"pass"}},[_c('el-input',{model:{value:(_vm.registro.nuevopass),callback:function ($$v) {_vm.$set(_vm.registro, "nuevopass", $$v)},expression:"registro.nuevopass"}})],1):_vm._e()],1),_c('el-col',{attrs:{"md":15}},[_c('el-form-item',{attrs:{"label":"Email","prop":"correo","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{model:{value:(_vm.registro.correo),callback:function ($$v) {_vm.$set(_vm.registro, "correo", $$v)},expression:"registro.correo"}})],1)],1),_c('el-col',{attrs:{"md":9}},[_c('el-form-item',{attrs:{"label":"Teléfono","prop":"telefono","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-input',{model:{value:(_vm.registro.telefono),callback:function ($$v) {_vm.$set(_vm.registro, "telefono", $$v)},expression:"registro.telefono"}})],1)],1),_c('el-col',{attrs:{"md":16}},[_c('el-form-item',{attrs:{"label":"Cliente","prop":"cuit"}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"value-key":"cuit","placeholder":"Buscar cliente","disabled":_vm.editar,"clearable":"","fetch-suggestions":_vm.querySearch},on:{"select":_vm.setCliente},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('small',{staticClass:"value",staticStyle:{"line-height":"1.2","display":"block","padding":"5px 0px","border-bottom":"1px solid #f1f1f1"}},[_vm._v(" "+_vm._s(item.cuit)+" - "+_vm._s(item.nombre)+" "),_c('br'),_vm._v(" "+_vm._s(item.fantasia)+" ")])]}}],null,false,1239300092),model:{value:(_vm.registro.cuit),callback:function ($$v) {_vm.$set(_vm.registro, "cuit", $$v)},expression:"registro.cuit"}})],1)],1),_c('el-col',{attrs:{"md":8}},[_c('el-form-item',{attrs:{"label":"Tipo","prop":"rol","rules":[
              {
                required: true,
                message: 'campo obligatorio',
                trigger: 'blur',
              },
            
            ]}},[_c('el-select',{attrs:{"value-key":"","placeholder":"","clearable":"","filterable":""},model:{value:(_vm.registro.rol),callback:function ($$v) {_vm.$set(_vm.registro, "rol", $$v)},expression:"registro.rol"}},_vm._l((_vm.roles),function(r){return _c('el-option',{key:r.id,attrs:{"label":r.nombre,"value":r.id}})}),1)],1)],1),_c('el-col',{attrs:{"md":24,"offset":0}},[_c('hr')]),_c('el-col',{attrs:{"md":24,"sm":8,"offset":0}},[_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary","loading":_vm.registrando},on:{"loading":_vm.cargando,"click":function($event){return _vm.registrarUsuario('registro')}}},[(_vm.editar)?_c('span',[_vm._v("EDITAR USUARIO")]):_c('span',[_vm._v(" AGREGAR USUARIO")])])],1)],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }