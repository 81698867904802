var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":12,"offset":0}},[_c('div',{staticClass:"datos"},[_c('el-form',{ref:"cuenta",attrs:{"model":_vm.cuenta,"label-position":'top',"inline":false,"size":"mini"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":16}},[_c('el-form-item',{attrs:{"label":"Nombre Completo","prop":"nombre","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{attrs:{"min":4},model:{value:(_vm.cuenta.nombre),callback:function ($$v) {_vm.$set(_vm.cuenta, "nombre", $$v)},expression:"cuenta.nombre"}})],1)],1),_c('el-col',{attrs:{"md":8}},[_c('el-form-item',{attrs:{"label":"CUIT","prop":"cuit","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.cuenta.cuit),callback:function ($$v) {_vm.$set(_vm.cuenta, "cuit", $$v)},expression:"cuenta.cuit"}})],1)],1),_c('el-col',{attrs:{"md":14}},[_c('el-form-item',{attrs:{"label":"Nombre de fantasía","prop":"fantasia"}},[_c('el-input',{model:{value:(_vm.cuenta.fantasia),callback:function ($$v) {_vm.$set(_vm.cuenta, "fantasia", $$v)},expression:"cuenta.fantasia"}})],1)],1),_c('el-col',{attrs:{"md":16}},[_c('el-form-item',{attrs:{"label":"Email","prop":"correo","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{model:{value:(_vm.cuenta.correo),callback:function ($$v) {_vm.$set(_vm.cuenta, "correo", $$v)},expression:"cuenta.correo"}})],1)],1),_c('el-col',{attrs:{"md":8}},[_c('el-form-item',{attrs:{"label":"Teléfono","prop":"telefono","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{model:{value:(_vm.cuenta.telefono),callback:function ($$v) {_vm.$set(_vm.cuenta, "telefono", $$v)},expression:"cuenta.telefono"}})],1)],1),_c('el-col',{attrs:{"md":16}},[_c('el-form-item',{attrs:{"label":"Dirección","prop":"direccion","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.cuenta.direccion),callback:function ($$v) {_vm.$set(_vm.cuenta, "direccion", $$v)},expression:"cuenta.direccion"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Localidad","prop":"localidad","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-input',{attrs:{"min":8},model:{value:(_vm.cuenta.localidad),callback:function ($$v) {_vm.$set(_vm.cuenta, "localidad", $$v)},expression:"cuenta.localidad"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Provincia","prop":"provincia","rules":[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"","clearable":"","filterable":"","auto-complete":"nope"},model:{value:(_vm.cuenta.provincia),callback:function ($$v) {_vm.$set(_vm.cuenta, "provincia", $$v)},expression:"cuenta.provincia"}},_vm._l((_vm.provincias),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.nombre}})}),1)],1)],1),_c('el-col',{attrs:{"md":24,"offset":0}},[_c('hr')]),_c('el-col',{attrs:{"md":12,"sm":12,"offset":0}},[_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary","loading":_vm.registrando},on:{"loading":_vm.cargando,"click":function($event){return _vm.actualizarCliente('cuenta')}}},[_vm._v("Actualizar datos")])],1)],1)],1)],1)],1)]),_c('el-col',{attrs:{"md":12,"offset":0}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }