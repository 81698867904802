<template>
  <div v-loading="subiendo">
    <el-card
      style="margin-top: 20px"
      shadow="always"
      :body-style="{ padding: '20px' }"
    >
      <el-row :gutter="20">
        <el-col :md="10" :offset="0">
          <div class="actualizar">
            Actualizacion masiva de productos:
            <ul>
              <li>Descargar listado completo de productos</li>
              <li>Actualizar datos desde el archivo</li>
              <li>Subir archivo descargado</li>
            </ul>
            <el-button
              type="primary"
              size="mini"
              :loading="descargando"
              @click="descargarProductos"
              >Descargar listado</el-button
            >
            <el-upload
              style="float: right"
              :action="urlImport"
              multiple
              ref="upload"
              :on-change="elegirArchivo"
              :on-success="onSuccess"
              :on-error="onError"
              :show-file-list="false"
            >
              <el-button size="mini" type="success"
                >Cargar listado Actualizado</el-button
              >
            </el-upload>
            <!-- <el-button type="success" size="mini" @click="cargarProductos">Cargar listado Actualizado</el-button> -->
          </div>
          <div class="actualizar">
            Importar nuevos productos

            <ul>
              <li>
                Debe contener al menos los siguientes campos

                <ul>
                  <li>codigo</li>
                  <li>nombre</li>
                  <li>precio</li>
                  <li>stock</li>
               
                  <li>unidades x bulto</li>
                  <li>categoria</li>
                  <li>marca</li>
                </ul>
              </li>
              <li>Si el código ya existe no se guardara el producto</li>
            </ul>

            <el-button type="info" size="small" @click="templateD">Descargar template</el-button>
              <el-upload
              style="float: right"
              :action="urlImportNew"
              multiple
              ref="upload"
              :on-change="elegirArchivo"
              :on-success="onSuccess"
              :on-error="onError"
              :show-file-list="false"
            >
              <el-button size="small" type="success"
                >Cargar archivo</el-button
              >
            </el-upload>
            
          </div>
        </el-col>
        <el-col :md="14" :offset="0">
          Agregar producto
          <nuevo-producto :producto_editar="-1" />
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import NuevoProducto from "@/components/admin/NuevoProducto.vue";
import { baseUrl ,FILES_PATH} from "@/axios";
export default {
  components: { NuevoProducto },
  data() {
    return {
      subiendo: false,
      baseUrl: baseUrl,
      templateE:FILES_PATH + '/template_productos.xls',
      urlImport: baseUrl + "/admin/actualizarProductos",
      urlImportNew: baseUrl + "/admin/agregarProductos",
      productos: {},
      user_id: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')).user_id : 0, 
      cliente_id: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')).cliente_id : 0, 
      accion:'Cargar listado actualizado',
      descargando: false,
    };
  },
  methods: {
    templateD()
    {
       window.open(this.templateE,'_blank' ) 
    },
    onSuccess(response) {
      this.subiendo = false;
      console.log("onSuccess", response);
      setTimeout(() => {
        this.subiendo = false;
        this.$refs.upload.clearFiles();
      }, 100);

      //   this.$alert('Ocurrio un problema durante la importación, verifique su conexión a internet o reintente con un archivo con menos de 1000 articulos')
    },
    onError() {
      setTimeout(() => {
        this.subiendo = false;
        this.$refs.upload.clearFiles();
      }, 500);
      this.$alert(
        "Ocurrio un problema durante la importación, verifique su conexión a internet o reintente con un archivo con menos de 2500 articulos"
      );
    },
    elegirArchivo() {
      this.subiendo = true;
    },
    descargarProductos() {
      this.descargando = true;
      let url = this.baseUrl + "/admin/listadoProductos";
      window.open(url);
      this.descargando = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.actualizar {
  background: #f1f1f1;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>