
<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <el-row :gutter="0">
        <el-col :md="24" >
          <el-tabs
            type="border-card"
          >
           <el-tab-pane label="Pedidos">
             <Pedidos />
            </el-tab-pane>
            <el-tab-pane label="Datos Personales">
             <datos-personales />
            </el-tab-pane>
            <!-- <el-tab-pane label="Direcciones"></el-tab-pane> -->
          </el-tabs>
        </el-col>
       
      </el-row>
    </transition>
  </div>
</template>

<script>
import Pedidos from "@/components/cuenta/pedidos";
import datosPersonales from "@/components/cuenta/datosPersonales";

export default {
components: {
    Pedidos,
    datosPersonales
}
};
</script>

