<template>
    <div>
        <el-card v-loading="procesando" shadow="always" style="margin-top:20px" :body-style="{ padding: '0px' }">
            <el-row :gutter="20">
                <el-col :md="8" :offset="0">
                    <div style="padding:10px; font-size:14px">
                        <p>
                            Procesar imágenes.
                            <br>
                            Está accion crea las imágenes miniatura y reduce el peso de las imágenes que se muestran en
                            la web.
                        </p>
                        <strong>Se recomienda subir imágenes cuadradas (1:1)</strong>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Archivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(f,index) in files" :key="index">
                                    <td>
                                        {{f.split('-')[0]}}
                                    </td>
                                    <td>
                                        {{f}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <el-button type="primary" size="default" @click="ProcesarFiles"> Procesar Imágenes
                            {{files.length}}</el-button>


                    </div>


                </el-col>
                <el-col :md="8">
                    <div style="padding:20px; background:#fafafa" >
                        <h4>Sitio en mantenimiento</h4>
                        <p v-if="mantenimiento">
                            Es sitio se encuentra en mantenimiento para el público
                        </p>
                        <p v-else>
                            El sitio esta habilitado para todo el público
                        </p>
                        <el-button type="primary" size="default" :loading="habilitando" @click="habilitar">
                            {{btnTxt}}
                        </el-button>

                    </div>

                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import {HTTP} from "@/axios"
    export default {
        data() {
            return {
                files: [],
                habilitando: false,
                procesando:false,
                mantenimiento: undefined,
                btnTxt:undefined
            }
        },
        created() {
          this.getFiles()
          this.getConfig()
        },
        methods: {
            habilitar()
            {
                this.habilitando = true
                HTTP.post('/admin/config', {mantenimiento:this.mantenimiento ? 0 : 1})
                    .then(res => {
                        this.habilitando=false
                        this.mantenimiento = undefined
                        console.log(res.data);
                        this.getConfig()
                    })
            },

            getConfig() {
                HTTP.get('/config')
                    .then(res => {
                        this.mantenimiento = res.data[0].mantenimiento
                        this.btnTxt = res.data[0].mantenimiento ? 'Habilitar el sitio' : 'Poner el sitio en mantenimiento'

                    })
                    .catch(() => {
                        this.$alert('No se pudo recuperar la información del servidor')
                    })
            },
            ProcesarFiles()
            {
                this.procesando = true
 HTTP.get('/admin/ProcesarFiles')
                    .then(res => {
                        if(res.data)
                        {
                            this.getFiles()
                             this.procesando = false
                        }
this.procesando = false
                    })
            },
            getFiles() {
                HTTP.get('/admin/getFiles')
                    .then(res => {
                        this.files = res.data
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>