<template>
  <el-dialog :visible.sync="nuevoUsuario" width="50%" @close="editar = false">
    <div class="container">
      <div v-if="agregado" style="padding:30px">
        <h1>Nueva cuenta agregada</h1>
        <p>Su cuenta ha sido creada en forma exitosa y una vez aprobada recibirá un correo con su usuario y contraseña.</p>
        <br />
        <strong>Noa Comercial</strong>
      </div>
      <el-form
        :model="registro"
        ref="registro"
        :label-position="'top'"
        :inline="false"
        size="medium"
        v-if="!agregado"
      >
        <el-row :gutter="20">
          <el-col :md="24">
            <el-form-item
              label="Nombre "
              prop="nombre"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input :min="4" v-model="registro.nombre"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Usuario"
              prop="user"
              
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input :disabled="editar" v-model="registro.user" :min="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
            v-if="!editar"
              label="Contraseña"
              prop="pass"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input  v-model="registro.pass"></el-input>
            </el-form-item>
               <el-form-item
            v-if="editar"
              label="cambiar contraseña"
              prop="pass"
            
            >
              <el-input  v-model="registro.nuevopass"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="15">
            <el-form-item
              label="Email"
              prop="correo"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.correo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="9">
            <el-form-item
              label="Teléfono"
              prop="telefono"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-input v-model="registro.telefono"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <el-form-item label="Cliente" prop="cuit">
              <el-autocomplete
                v-model="registro.cuit"
                value-key="cuit"
                style="width:100%"
                placeholder="Buscar cliente"
                 :disabled="editar"
                clearable
                :fetch-suggestions="querySearch"
                @select="setCliente"
              >
                <template slot-scope="{ item }">
                  <small
                    class="value"
                    style="line-height:1.2; display:block; padding:5px 0px; border-bottom:1px solid #f1f1f1"
                  >
                    {{ item.cuit }} - {{ item.nombre }}
                    <br />
                    {{ item.fantasia }}
                  </small>
                </template>
              </el-autocomplete>

              <!-- <el-select v-model="registro.cliente_id" value-key="" style="width:100%" placeholder="" clearable filterable >
                  <el-option v-for="c in clientes"
                      :key="c.id"
                      :label="c.cuit + '-' + c.fantasia" 
                      :value="c.id">
                  </el-option>
              </el-select>-->
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item
              label="Tipo"
              prop="rol"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              
              ]"
            >
              <el-select v-model="registro.rol" value-key placeholder clearable filterable>
                <el-option v-for="r in roles" :key="r.id" :label="r.nombre" :value="r.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="24" :offset="0">
            <hr />
          </el-col>

          <el-col :md="24" :sm="8" :offset="0">
            <el-form-item>
              <el-button
                @loading="cargando"
                type="primary"
                class="btn-block"
                :loading="registrando"
                @click="registrarUsuario('registro')"
              >
              <span v-if="editar">EDITAR USUARIO</span>
              <span v-else> AGREGAR USUARIO</span>
             
              
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { HTTP } from "@/axios"
import { EventBus } from "@/event-bus"
import provincias from "@/assets/provincias.json"
export default {
  data() {
    return {
 editar:false,
      nuevoUsuario: false,
      roles: [
        { id: 1, nombre: 'Cliente' },
        { id: 33, nombre: 'Vendedor' },
        { id: 99, nombre: 'Admin' },
      ],
      registrando: false,
      cargando: false,
      agregado: false,
      vista: 'registro',
      provincias: provincias.provincias,
      login: {},
      recuperar: {},
      registro: {
        entrega: 'misma'
      }
    };
  },
  created() {
    EventBus.$on('nuevoUsuario', (data) => {
      this.nuevoUsuario = true
      this.registro = data
      this.registro.editar = data.id
      if(data && data.id)
      {
        this.editar = true
      }

    })
    if (this.$route.query.vista) {
      this.vista = this.$route.query.vista
    }
  },
  methods: {
    setCliente(item) {
      console.log(item);
      this.registro.cliente_id = item.id
    },
    querySearch(queryString, cb) {
      HTTP.post('/admin/BuscarCliente', { buscar: queryString })
        .then(res => {
          console.log(res);
          cb(res.data);
        })

    },
    submitForm(formName) {
      console.log('for');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.cargando = true
          window.sessionStorage.clear()
          //  if(this.login.user === 'admin')
          //       {
          //           this.$router.push({name: 'catalogo'})
          //           window.sessionStorage.setItem('cliente', 1)
          //           window.sessionStorage.setItem('admin', 1)
          //       }
          //       if(this.login.user === 'cliente')
          //       {
          //           this.$router.push({name: 'catalogo'})
          //           window.sessionStorage.setItem('cliente', 1)
          //       }
          HTTP.post('/login', this.login)
            .then(res => {
              this.cargando = false
              window.sessionStorage.setItem('cliente', JSON.stringify(res.data))
              this.$router.push({ name: 'catalogo' })
            })
            .catch(() => {
              this.$alert('Datos incorrectos o usuario no habilitado', { type: 'error' })
              this.cargando = false
            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    registrarUsuario(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registrando = true
          HTTP.post('/admin/AgregarUsuario', this.registro)
            .then(res => {
              this.registrando = false
              //    this.agregado = true
              console.log(res)
              this.$alert('Usuario creado/editado correctamente', { type: 'success' })
              EventBus.$emit('actualizarUsuarios', 1)
              this.registro = {}
              this.nuevoUsuario =false
            })
            .catch(e => {
              this.registrando = false
              this.$alert('No se pudo crear el cliente, verifique los datos ingresados y reitente')

              if (e.response) {
                if (e.response.status === 422) {
                  this.$alert('El CUIT: ' + this.registro.cuit + ' ya posee un registro. Si no recuerda sus datos de accesos presione el boton Regresar y seleccione la opción OLVIDE MIS DATOS ', e)
                }
              }

            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    recuperarPass() {
      if (this.registro.user || this.registro.correo || this.registro.telefono) {
        console.log('recupera');
      }
      else {

        this.$alert('Debe ingresar al menos un dato', {
          type: 'error'
        })
      }
    }
  },
};
</script>

<style lang="scss">
.login > .el-card__header {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  background: #d32019;
  color: white;
  line-height: 1;
  font-size: 18px;
}
.logo {
  max-width: 260px;
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: center;
}
</style>
