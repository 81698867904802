<template>
    <div>
        <el-row :gutter="20">
            <el-col :md="12" :offset="0">
                <div class="datos">
                <el-form
                  :model="cuenta"
                  ref="cuenta"
                  :label-position="'top'"
                  :inline="false"
                  size="mini"
                >
                  <el-row :gutter="20">
                    <el-col :md="16">
                      <el-form-item
                        label="Nombre Completo"
                        prop="nombre"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input :min="4" v-model="cuenta.nombre"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="8">
                      <el-form-item
                        label="CUIT"
                        prop="cuit"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input v-model="cuenta.cuit" :min="8"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="14">
                      <el-form-item label="Nombre de fantasía" prop="fantasia">
                        <el-input v-model="cuenta.fantasia"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="16">
                      <el-form-item
                        label="Email"
                        prop="correo"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input v-model="cuenta.correo"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="8">
                      <el-form-item
                        label="Teléfono"
                        prop="telefono"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input v-model="cuenta.telefono"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="16">
                      <el-form-item
                        label="Dirección"
                        prop="direccion"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="cuenta.direccion"
                          :min="8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="12">
                      <el-form-item
                        label="Localidad"
                        prop="localidad"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="cuenta.localidad"
                          :min="8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :md="12">
                      <el-form-item
                        label="Provincia"
                        prop="provincia"
                        :rules="[
                          {
                            required: true,
                            message: 'campo obligatorio',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-select
                          v-model="cuenta.provincia"
                          style="width: 100%"
                          placeholder=""
                          clearable
                          filterable
                          auto-complete="nope"
                        >
                          <el-option
                            v-for="item in provincias"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.nombre"
                          >
                          </el-option>
                        </el-select>

                        <!-- <el-input v-model="cuenta.pass" :min="8" ></el-input> -->
                      </el-form-item>
                    </el-col>

                    <el-col :md="24" :offset="0">
                      <hr />
                    </el-col>

                    <el-col :md="12" :sm="12" :offset="0">
                      <el-form-item>
                        <el-button
                          @loading="cargando"
                          type="primary"
                          class="btn-block"
                          :loading="registrando"
                          @click="actualizarCliente('cuenta')"
                          >Actualizar datos</el-button
                        >
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
            <el-col :md="12" :offset="0"></el-col>
        </el-row>
        
 
    </div>
</template>

<script>
import foMixins from "@/mixins/foMixins.js";
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
import provincias from "@/assets/provincias.json";
    export default {
          

  mixins: [foMixins],
  data() {
    return {
      selectPedido: false,
      cuenta: {},
      estado:0,
      registrando: false,
      cargando: false,
      provincias: provincias.provincias,

    };
  },
  created() {
    EventBus.$on("verMas", (v) => {
      this.selectPedido = v;
    });
     EventBus.$on("cancelarPedido", () => {
       this.getDatosCuenta();
        this.selectPedido = 0;
    });
    this.getDatosCuenta();
  },
  methods: {

    actualizarCliente(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registrando = true;
          HTTP.post("/noa/actualizarCliente", this.cuenta)
            .then((res) => {
              this.registrando = false;
              this.agregado = true;
              console.log(res);
              this.$alert("Datos actualizados", { type: "success" });
            })
            .catch(() => {
              this.registrando = false;
              this.$alert(
                "No se pudo actualizar el cliente, verifique los datos ingresados y reitente"
              );
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    verMas(p) {
      console.log(p);
      this.selectPedido = p.id;
      this.estado = p.estado
    },
    getDatosCuenta() {
      HTTP.post("/noa/getDatosCuenta", { user_id: this.user })
        .then((res) => {
          this.cuenta = res.data;
        })
        .catch(() => {
          this.$alert("No se pudo recuperar la información de la cuenta");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pendiente{
    background:cornsilk;
    color:#111;
      padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;
}
.cancelado{
   background: #f44336;
    color: #FFF;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;

}
.confirmado{
    background:#ffc107;
    color:#111;
      padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;
}
.preparacion{
     background:#2196f3;
    color:#fff;
      padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;
}
.camino{
    background:#ffc107;
    color:#fff;
      padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;
}
.entregado{
    background:#cddc39;
    color:#111;
      padding: 2px 4px;
    border-radius: 5px;
    font-size: 14px;
}
.pedidos {
  padding: 0px 20px;
}
.pedidos h3 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: normal;
  margin: 0;
  padding: 0px;
  border-bottom: 2px solid #e1e1e1;
  margin-bottom: 10px;
  padding-bottom: 6px;
  padding-top: 7px;
}
.pedidos small {
  color: #f44336;
  text-transform: capitalize;
}
.datos {
//   background-color: #fafafa;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  border-right: 1px solid #f1f1f1;
  padding-top: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>